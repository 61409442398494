import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"
import ServiceCard from "../components/servicecard"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faServer, faChalkboardTeacher, faChartBar, faMobileAlt, faNetworkWired, faStore, faUserTag } from '@fortawesome/free-solid-svg-icons'

const ServicesPage = () => (
  <Layout>
    <PageTitle title="New Vertical Technology Services" page="PageTitleServices" />
    <Seo title="New Vertical Technology Services"
         description="We offer a variety of services to help solve challenging technical problems, provide consulting,and build websites to meet your business needs. "
         pathname={"/services/"}/>
    <div className="content-page-wrapper">
      <div className="service-wrapper">
        <ServiceCard icon={faServer} title="Managed Services" link="#ManagedServices" desc="Managed Services is handing backups, security and patching for computers and servers." />
        <ServiceCard icon={faNetworkWired} title="Cloud Computing" link="#CloudComputing" desc="We transfer on-prem systems to AWS and Azure along with set up and support of Office 365." />
        <ServiceCard icon={faStore} title="Apps and Websites" link="#WebSection" desc="We can help design and deliver a apps and websites for any type of business." />
        <ServiceCard icon={faChalkboardTeacher} title="Software Services" link="#SoftwareServices" desc="We can provide general technology and cloud consulting to support your organization's needs." />
        <ServiceCard icon={faChartBar} title="Technical Consulting" link="#Consulting" desc="We can help answer technical questions and help with the decision making process." />
        <ServiceCard icon={faUserTag} title="Computer Support" link="#ComputerSupport" desc="We can help you with basic computer problems and get you back up and running in no time." />
      
      </div>
      <div className="service-card">
        <h2 id="ManagedServices"><FontAwesomeIcon icon={faServer} /> Managed Services</h2>
        <p>
          At New Vertical Technologies we offer fully Managed Services that will help you maintain your computers and servers, perform backups and manage the technology required to make your company run.  We have
          partnerships with many software and hardware vendors to offer the best of class technology along with experts to implement and maintain those systems. Some of the systems we manage are:
        </p>
        <ul>
          <li>Office Computers including monitoring, updates and security checks.</li>
          <li>Office Servers with software support.</li>
          <li>Backups and backup monitoring</li>
          <li>Firewall and VPN management</li>
          <li>Network and wireless access</li>
          <li>Email and spam protection with identity and threat management.</li>
        </ul>
        <p>
          We can start with the basics.  You may not need much, so we will simply pick up where you need services and assist where necessary.  This allows us to offer very minimal rates for simple monitoring services
          while giving you access to our vendor relationships.
        </p>

      </div>
      <div className="service-card">

        <h2 id="CloudComputing"><FontAwesomeIcon icon={faNetworkWired} />Cloud Computing</h2>
        <p>
         Cloud computing involves more than simply email.  Amazon AWS, Microsoft Azure and Google GCE have completely changed the way servers and technology are delivered to companies.  Many companies and goverment agencies and
          found many cost savings by moving their systems to the cloud.  The process of moving the systems are roughly the following:
        </p>
        <ol>
          <li>Understand current on-prem requirements as well as who needs access to those systems.</li>
          <li>Calculate a cost of services by moving to the cloud and required storage volume.</li>
          <li>Determine a migration plan that will minimize down time, ensure security and reduce impact as much as possible.</li>
          <li>Execute the plan at a time when it is best for you and your organization.</li>
        </ol>
        <p>
          Many software systems can be used as-is in the cloud.  This can greatly increase stability with systems that are hosted on aging hardware or are no longer supported.  On-prem servers are a ticking time-bomb in many instances and
          a cloud migration may be the only options.  New Vertical Technologies can help.
        </p>
      </div>            
      <div className="service-card">        
        <h2 id="WebSection"><FontAwesomeIcon icon={faChartBar} />  Apps and Websites</h2>
        <p>
          A company website serves the purpose of providing a visible location for new and existing web users to get valuable information about your company.  Your website can generate leads as well as take the pressure off of your
          phone attendants, executives and support staff.  However, there are many different ways to build websites and costs can range from a few hundred bucks to thousands.  New Vertical adds value to websites using the following:
        </p>
        <ul>
          <li>Perform a top down review of the company services, products and current customers.</li>
          <li>Understand the companies marketing objectives, mission statement and guiding principals.</li>
          <li>Lay out and design a good flow that presents information in a good consistent way.</li>
          <li>Begin to position the business for key-word searches in google and set up analytics.</li>
          <li>Build strategies for on-going content development and publications that will help the company rank higher in search.</li>
        </ul>
        <p>Apps development has given companies new ways to reach and manage clients.  Even though generally more expensive than websites, apps serve very important roles in ensuring operations are smooth operating and customer
        service is kept to high standards.  Apps can be as simple as a contact us form for existing customers, or can be as complex as managing an entire workforce.  Since nearly everyone over the age of 14 has a mobile app in the U.S. not
        considering add an app to your companies workflow is a mistake.
        </p>
      </div>
      <div className="service-card">
        <h2 id="SoftwareServices"><FontAwesomeIcon icon={faStore} /> Software Service</h2>
        <p>
          Software is a glue of services and what enables your company or organization to offer competitive services and rates.  Our software services can mostly be summarized in one statement:  the consumption and display of important data to
          people who need it the most.  Data can be located anywhere, though if your organization cannot get or see that data, then producing business outcomes can become impossible.  Companies are driven by data now and data exists everywhere.
          We ensure consistent access to that data. The software that manages that data could be quickbooks or off the shelf software, or that software could be custom written.  Some of the systems we manage are the following:
        </p>
        <ul>
          <li>Maintaining custom web and windows applications written in various programming languages.</li>
          <li>Building new applications for consuming data.</li>
          <li>Connecting systems together to produce new results.</li>
          <li>Migrating systems to new or upgraded technologies.</li>
          <li>Ensuring connections to software running on servers.</li>
        </ul>
      </div>
      <div className="service-card">        
        <h2 id="Consulting"><FontAwesomeIcon icon={faChalkboardTeacher} />Technical Consulting</h2>
        <p>
          You know you need something, but not sure what that is.  You may want to build something, but have no idea how much it costs or how much effort it will take.  Someone could be offering a major technology service, but you just do not know
          how to communicate with that team.  Maybe you want to upgrade everything and not sure how to get started.

        </p>
        <p>
          That is where technology consulting can help you answer those questions.  We have been in the technology services industry for a long time and have significant education that will help give you the best picture possible for what
          a technology project looks like.  Consulting can give you that go / no-go decision making process you need to ensure success. We can offer a completely unbiased opinion to technology that may give you that outsiders opinion critical
          to making sure your best interests are protected.  We offer the following consulting services:
        </p>
        <ul>
          <li>Total Cost of Ownership</li>
          <li>Impact analysis</li>
          <li>Implementation plans</li>
          <li>Technology architecture plans</li>
          <li>Holistic technology positioning</li>
          <li>Technical documentation review</li>
          <li>Competitive analysis and technical score cards</li>
          <li>Technology key performance indicators.</li>
        </ul>
      </div>
      <div className="service-card">        
        <h2 id="RetailSection"><FontAwesomeIcon icon={faUserTag} /> Computer Support</h2>
        <p>
        We can also help fix broken computers.  We are in a local area and sometimes customers simply have a technical need that includes simple services.  We certainly have the skills and services necessary to perform these services.  This includes:
        </p>
        <ul>
          <li>Hardware upgrades and repair</li>
          <li>Data Recovery</li>
          <li>Data transfers</li>
          <li>Screen replacements</li>
        </ul>
      </div>
    </div>        
  </Layout>
)

export default ServicesPage
