import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faChartBar, faMobileAlt, faNetworkWired, faStore, faUserTag } from '@fortawesome/free-solid-svg-icons'


const ServiceCard = ( props ) => (
    <a href={props.link}>
    <div class='service'>
        <h2 class='service-title'>
            {props.title}
        </h2>
        
        <div class="service-icon">
            <FontAwesomeIcon icon={props.icon} />
        </div>

        <div class='service-desc'>
            {props.desc}
        </div> 
    </div>
    </a>
)
export default ServiceCard
